import { LocationType } from '@sortlist-frontend/shared-components';
import { getStorage, setStorageWithEvent } from '@sortlist-frontend/utils';
import { Dispatch, SetStateAction } from 'react';

import { Skill } from '_types/generic';

export const ANIMATION_TIME = 1000;

export const MAX_COMPARED_AGENCIES = 10;

export type SingleAgency = {
  slug: string;
  name?: string;
  logo?: string;
};

export type SearchFormData = {
  query?: Skill[];
  size?: number[][];
  languages?: string[];
  expertiseIds?: number[];
  budget?: number[];
  prospects?: Pick<LocationType, 'address' | 'polygon' | 'polygon'>;
  sectorIds?: number[];
};

export type ComparedAgency = {
  slug: string;
  searchContext?: SearchFormData;
};

export type ComparedAgenciesSet = Dispatch<SetStateAction<ComparedAgency[]>>;

export const COMPARE_STORAGE_KEY = 'sortlistComparator';
export const COMPARE_DEMO_STORAGE_KEY = 'sortlistComparatorDemo';

export const isComparing = Boolean(getStorage(COMPARE_STORAGE_KEY));

export const getComparedAgencies = (): ComparedAgency[] => JSON.parse(getStorage(COMPARE_STORAGE_KEY) || '[]');

export const setComparedAgencies = (agencies: ComparedAgency[]): ComparedAgency[] => {
  const oldAgencies: ComparedAgency[] = getComparedAgencies();
  const oldSlugs = oldAgencies.map(({ slug }) => slug);
  const netNews = agencies.filter(({ slug: newSlug }) => !oldSlugs.includes(newSlug));
  const newAgencies = [...oldAgencies, ...netNews].slice(0, MAX_COMPARED_AGENCIES - 1);
  return setStorageWithEvent(COMPARE_STORAGE_KEY, JSON.stringify(newAgencies)) ? newAgencies : oldAgencies;
};

export const initCompare = () => {
  return setStorageWithEvent(COMPARE_STORAGE_KEY, getStorage(COMPARE_STORAGE_KEY) || '[]');
};

export const compare = (newAgency: ComparedAgency): ComparedAgency[] | false => {
  const oldAgencies = getComparedAgencies();
  if (oldAgencies.length === MAX_COMPARED_AGENCIES) return false;
  if (oldAgencies.some((oldAgency) => oldAgency.slug === newAgency.slug)) return oldAgencies;
  const newAgencies = [...oldAgencies, newAgency];
  return setStorageWithEvent(COMPARE_STORAGE_KEY, JSON.stringify(newAgencies)) ? newAgencies : oldAgencies;
};

export const uncompare = (removedAgency: ComparedAgency): ComparedAgency[] => {
  const oldAgencies = getComparedAgencies();
  const newAgencies = oldAgencies.filter((oldAgency) => oldAgency.slug !== removedAgency.slug);
  return setStorageWithEvent(COMPARE_STORAGE_KEY, JSON.stringify(newAgencies)) ? newAgencies : oldAgencies;
};

export const syncStorage = (callback: ComparedAgenciesSet) => callback(getComparedAgencies());

export const clearCompare = () => {
  return setStorageWithEvent(COMPARE_STORAGE_KEY, '[]');
};
