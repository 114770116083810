import { TFunction } from '@sortlist-frontend/translation/server-only';

import { DomainInfo } from '_backend/integration/domain-info';
import { NavigationData } from '_components/layout/Layout';

const getCountryLabels = (t: TFunction) => {
  return {
    be: t('common:footer.countries.be'),
    ca: t('common:footer.countries.ca'),
    ch: t('common:footer.countries.ch'),
    de: t('common:footer.countries.de'),
    es: t('common:footer.countries.es'),
    fr: t('common:footer.countries.fr'),
    gb: t('common:footer.countries.gb'),
    it: t('common:footer.countries.it'),
    nl: t('common:footer.countries.nl'),
    us: t('common:footer.countries.us'),
    world: t('common:footer.countries.world'),
  };
};

export const getCountryNameByIso = (iso31661: string, t: TFunction) => {
  const labels = getCountryLabels(t);
  return labels[(iso31661?.toLowerCase() as keyof typeof labels) ?? 'world'];
};

export const getDomainUrl = (origin: string | undefined | null, currentOrigin: string) => {
  if (origin?.includes('sandbox')) {
    // Origin = https://fr-pr-public-ui-3258-public.sandbox.sortlist.cloud/
    const sandboxChunks = origin.replace('https://', '').split('-')[0];

    if (currentOrigin === sandboxChunks) return origin;

    const cleanedOrigin = origin.replace('https://', '');
    const cleanedOriginChunks = cleanedOrigin.split('-');

    if (cleanedOriginChunks[0] === 'pr') {
      cleanedOriginChunks.unshift(currentOrigin);
    } else if (currentOrigin === 'pr' && cleanedOriginChunks[0] !== 'pr') {
      cleanedOriginChunks.shift();
    } else {
      cleanedOriginChunks[0] = currentOrigin === 'gb' ? 'uk' : currentOrigin;
    }

    return `https://${cleanedOriginChunks.join('-')}`;
  }

  return currentOrigin;
};

type DomainMenuItem = {
  url: string;
  label: string;
  icon?: string;
  iso31661: string | null;
  domainInfoLocale: string;
  locales: Record<string, Record<string, string>>;
};

export const getDomainItems = (navigationData: NavigationData, domainInfo: DomainInfo | null, t: TFunction) => {
  const { origin, languageSwitcthRedirectHome, locale, resolvedUrl } = navigationData;
  const domainInfoObject = origin ? DomainInfo.getDomainInfoObject(origin) : {};

  return Object.keys(domainInfoObject).reduce((acc: DomainMenuItem[], domainKey) => {
    const domainInfoObj = domainInfoObject[domainKey];
    const domainInfoLocales = Object.keys(domainInfoObj?.locales);

    domainInfoLocales.map((currentLocale) => {
      // If it's custom then it's a logtail and we don't have that page on all the domains => we redirect to home
      const currentPath = languageSwitcthRedirectHome === true ? '/' : resolvedUrl;
      const currentLocation =
        domainInfo?.getMainLocale() === locale ? currentPath : currentPath?.replace(`/${locale}`, '');
      const domainLocale =
        domainInfoObj.main_locale != null && domainInfoObj.main_locale === currentLocale ? '' : `/${currentLocale}`;

      const currentUrl = `${getDomainUrl(origin, domainKey)}${domainLocale}${currentLocation !== '/' ? currentLocation : ''}`;

      acc.push({
        url: currentUrl,
        domainInfoLocale: currentLocale,
        label: getCountryNameByIso(domainInfoObj?.iso31661 as string, t) as string,
        icon: domainInfoObj.icon,
        iso31661: domainInfoObj.iso31661,
        locales: domainInfoObj?.locales,
      });
    });
    return acc;
  }, []);
};
