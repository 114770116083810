import { Logo } from '@sortlist-frontend/design-system/ssr';

import { DomainInfo } from '_backend/integration/domain-info';
import { Obfuscate } from '_components/common/Obfuscate/Obfuscate';
import { LayoutElementsProps } from '_components/layout/Layout';
import { getExternalResourcesUrl } from '_components/layout/utils';

import { getUrlHelper } from '../DesktopNav/utils';
import { MainMenu } from './MainMenu';
import { MobileMenuHeader } from './MobileMenu/MobileMenuHeader';
import { MobileMenuItem } from './MobileMenu/MobileMenuItem';
import { SearchMenu } from './SearchMenu';

export const MobileNav = (props: LayoutElementsProps) => {
  const { links, t, navigationData, comparatorCTA, searchMobile } = props;

  const domainInfo = DomainInfo.getFromOrigin(navigationData.origin);
  const locale = navigationData.locale;

  const getUrl = getUrlHelper(domainInfo, locale);
  return (
    <div data-testid="mobile-nav" className="layout-fill layout-row layout-align-space-between-center hide-gt-sm px-16">
      <div className="flex layout-row layout-align-start-center">
        <MainMenu
          navigationData={navigationData}
          findAgenciesMenu={{
            title: t('common:navbar.menu.findAgencies_V2'),
            explore: {
              title: t('common:navbar.findAgencies.explore.title'),
              subtitle: t('common:navbar.findAgencies.explore.subtitle_V2'),
            },
            postAProject: {
              title: t('common:navbar.findAgencies.postAProject.title'),
              subtitle: t('common:navbar.findAgencies.postAProject.subtitle_V2'),
            },
            getAdvice: {
              title: t('common:navbar.findAgencies.getAdvice.title'),
              subtitle: t('common:navbar.findAgencies.getAdvice.subtitle_V2'),
            },
          }}
          exploreMenu={{
            title: t('common:navbar.findAgencies.explore.title'),
          }}
          links={links}>
          <div className="layout-column layout-align-start-stretch" role="menu">
            <section id="get-clients-menu">
              <MobileMenuHeader title={t('common:navbar.menu.getClients')} className="pt-48" />
              <ul className="list-reset">
                <MobileMenuItem
                  link={getUrl('providers')}
                  dataTestid="navbar-apply"
                  title={t('common:navbar.getClientsMenu.apply_V2')}
                  subtitle={t('common:navbar.getClientsMenu.applyDescription')}
                  className="border-bottom border-secondary-300"
                />
                <MobileMenuItem
                  link={getUrl('providers.pricing')}
                  dataTestid="navbar-pricing"
                  title={t('common:navbar.getClientsMenu.pricing')}
                  subtitle={t('common:navbar.getClientsMenu.pricingDescription')}
                  className="border-bottom border-secondary-300"
                />
              </ul>
            </section>

            <section id="resources-menu">
              <MobileMenuHeader title={t('common:navbar.menu.resources')} className="pt-48" />
              <ul className="list-reset">
                {domainInfo?.hasBlog() && (
                  <MobileMenuItem
                    link={getExternalResourcesUrl('blog', domainInfo, locale)}
                    dataTestid="navbar-blog"
                    title={t('common:navbar.resourcesMenu.blog')}
                    subtitle={t('common:navbar.resourcesMenu.blogDescription')}
                    className="border-bottom border-secondary-300"
                  />
                )}
                {domainInfo?.hasDataHub() && (
                  <MobileMenuItem
                    link={getExternalResourcesUrl('datahub', domainInfo, locale)}
                    dataTestid="navbar-datahub"
                    title={t('common:navbar.resourcesMenu.datahub_v1')}
                    subtitle={t('common:navbar.resourcesMenu.datahubDescription')}
                    className="border-bottom border-secondary-300"
                  />
                )}
                <MobileMenuItem
                  link={getUrl('stories')}
                  dataTestid="navbar-stories"
                  title={t('common:navbar.resourcesMenu.stories')}
                  subtitle={t('common:navbar.resourcesMenu.storiesDescription')}
                  className="border-bottom border-secondary-300"
                />
                <MobileMenuItem
                  link={getUrl('info.help')}
                  dataTestid="navbar-help"
                  title={t('common:navbar.resourcesMenu.help&Support')}
                  subtitle={t('common:navbar.resourcesMenu.help&SupportDescription')}
                  className="border-bottom border-secondary-300"
                  obfuscated
                />
              </ul>
            </section>

            <section id="account-menu" className="pt-48">
              <MobileMenuHeader title={t('common:navbar.menu.account')} />
              <ul className="list-reset">
                <MobileMenuItem
                  dataTestid="navbar-menu-signup-button"
                  title={t('common:navbar.accountMenu.signup')}
                  link={getUrl('join')}
                  className="border-bottom border-secondary-300"
                />
                <MobileMenuItem
                  dataTestid="navbar-menu-login-button"
                  title={t('common:navbar.accountMenu.login')}
                  link={getUrl('selectLoginType')}
                />
              </ul>
            </section>
          </div>
        </MainMenu>
      </div>
      <div className={'layout-column layout-align-center-center'}>
        <Obfuscate href={domainInfo?.getLocalizedUrl(locale) as string} obfuscate={true}>
          <Logo type="monogram" height={28} />
        </Obfuscate>
      </div>
      <div className="flex layout-row layout-align-end-center">
        <div className="px-8" style={{ display: 'flex' }}>
          {comparatorCTA}
        </div>
        {searchMobile != null && (
          <div className="px-8">
            <SearchMenu title={t('common:navbar.menu.search')} search={searchMobile} />
          </div>
        )}
      </div>
    </div>
  );
};
