import { useTracker } from '_core/hooks/use-tracker';

type Properties = {
  loggedIn?: boolean;
  query?: string;
  location?: string | null;
  industryIds?: number[];
  page?: string;
  cta?: string;
  element?: string;
  tech?: string;
  topic?: string;
  url?: string;
  locale?: string;
};

export const trackAgenciesSearched = (props: Properties) => {
  const { loggedIn, query, location, industryIds, page, element, tech, topic, url } = props;
  const { trackUntyped } = useTracker();

  trackUntyped('agenciesSearched', {
    loggedIn,
    query,
    location,
    industryIds,
    page,
    element,
    tech,
    topic,
    url,
  });
};
