import { cx } from '@emotion/css';
import { SearchRounded } from '@mui/icons-material';
import { Option, Select, SelectProps, SelectRef } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { useDebounceCallback } from '@sortlist-frontend/utils';
import { forwardRef, Fragment, useMemo, useState } from 'react';

import { NavigationData } from '_components/layout/Layout';
import { useTopicsForFilters } from '_core/repos/public-api/topics.repo';

type Props = {
  page: string;
  cta: string;
  element: string;
  navigationData: NavigationData;
  mobile?: boolean;
  extraTopicId?: string;
  type: 'service' | 'location';
} & SelectProps;

export const SearchTopicsInnerForFilters = forwardRef<SelectRef, Props>((props, ref) => {
  const {
    placeholder,
    navigationData,
    mobile = false,
    className,
    type,
    extraTopicId,
    onChange,
    ...selectProps
  } = props;
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');

  const { domain, locale, origin } = navigationData;

  const { data: topics, isLoading: isTopicsLoading } = useTopicsForFilters(
    domain,
    domain != null ? [extraTopicId ?? domain?.location?.id] : [],
    locale,
    type,
    searchValue,
    origin,
    domain != null,
  );

  const isLoading = Boolean(selectProps.isLoading) || isTopicsLoading;

  // Remove duplicate names even though they have different ids
  // The backend sorts these with the page with the most agencies first
  // This is the one selected here
  const uniqueTopics = useMemo(
    () => topics?.filter((item, index, self) => self.findIndex((obj) => obj.name === item.name) === index),
    [topics],
  );

  const options: Option[] = uniqueTopics?.map((topic) => ({ value: topic.id, label: topic.name })) ?? [];
  const handleInputChange = useDebounceCallback((searchValue: string) => {
    setSearchValue(searchValue);
  }, 300);

  return (
    <Select
      ref={ref}
      id={mobile ? 'search-service-select-mobile' : 'search-service-select'}
      className={cx('flex search-service-select', className)}
      placeholder={placeholder}
      options={options}
      noOptionsMessage={() => <Fragment>{t('common:navbar.menu.whatServiceNoOptions')}</Fragment>}
      value={null}
      instanceId={mobile ? 'search-service-select-mobile' : 'search-service-select'}
      isLoading={isLoading}
      inputId={mobile ? 'service-search-mobile' : 'service-search'}
      onInputChange={handleInputChange}
      onChange={(option, info) => {
        if (onChange != null) onChange(option, info);
      }}
      components={{
        ...(searchValue == null ? { Menu: () => null } : {}),
        DropdownIndicator: () => <SearchRounded fontSize="inherit" />,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
      {...selectProps}
    />
  );
});
